import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import SessionManager from "common/SessionManager";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { mainSearchDTO } from 'data/types';
import  constants  from 'common/constants';
import AllSearchInputNavBar from "../HeroSearchForm/AllSearchInputNavBar";
import { SearchService } from "common/util";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import CitySearchModal from "./CitySearchModal";
import CategoryFilterDropdown from "./CategoryFilterDropdown";
import SortByDropdown from "./SortByDropdown";


const MainNav2 = () => {
  const [isloggedin, setisloggedin] = useState(SessionManager.getUser());
  const [searchResult, setSearchResult] = useState<mainSearchDTO[]>([]);
  const [locationInputValue, setLocationInputValue] = useState("");
  const [dateFocused, setDateFocused] = useState<boolean>(false);

  function searchText(e: React.SetStateAction<string>) {
    setLocationInputValue(e);

    fetch(constants.API_CONTEXT_URL + '/suggestion/mainSearch?search=' + e)
				.then(async response => {
					const isJson = response.headers.get('content-type')?.includes('application/json');
					const data = isJson && await response.json();
	
					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					}
					setSearchResult(data.response);
				})
				.catch(error => {
				
				});
  }
  function onInputDone (item:any) {
    SearchService.route(item);
  }
  const goback =() =>{
    window.history.back();
  }

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10`}>
      <div className="px-1 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
          <div className="hidden lg:block">
          {/* <DropdownTravelers /> */}
            <form className="w-full p-1 relative flex rounded-3xl bg-white border border-neutral-300">
              {searchResult ?
                <AllSearchInputNavBar
                  defaultValue={locationInputValue}
                  onChange={(e) => searchText(e)}
                  className="flex-[1]"
                  recentSearch={searchResult}
                  searchResult={searchResult}
                  onInputDone={onInputDone}
                  isMobile={false}
                />
                : <></>
              }
            </form>
          </div>
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          {/* mobile view eSanchari search */}
          <div className="flex">
            <div>
              <div className="flex">
              <ChevronLeftIcon className="w-12 h-10" onClick={goback}>Back</ChevronLeftIcon>
              <form className="w-full p-1 relative flex rounded-3xl bg-white border border-neutral-300">
                {searchResult ?
                  <AllSearchInputNavBar
                    defaultValue={locationInputValue}
                    onChange={(e) => searchText(e)}
                    className="flex-[1]"
                    recentSearch={searchResult}
                    searchResult={searchResult}
                    onInputDone={onInputDone}
                    isMobile={true}
                  />
                  : <></>
                }
              </form>
              </div>
              <div className="pt-1 px-2 flex">
                <CategoryFilterDropdown />
                <SortByDropdown />
                <CitySearchModal />
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <CitySearchModal/>
            <CurrencyDropdown />
            <div className="pr-1.5">
              <NotifyDropdown className="-ml-2 xl:-ml-1" />
            </div>
            {isloggedin != null ?
            <AvatarDropdown />
            : <ButtonPrimary href="login">Sign In</ButtonPrimary>}
          </div>
          {/* <div className="flex items-center space-x-2 lg:hidden">
            <NotifyDropdown />
            {isloggedin !=  null?
            <AvatarDropdown />
            : <ButtonPrimary href="login">Sign In</ButtonPrimary>}
            <MenuBar />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
