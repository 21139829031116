import React, { FC, useState } from "react";
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { Order, GeneralPackage, packageItems,PriceRates, ProductDetailsDataType, ProductDataType } from './../../data/types';
import ButtonDelete from "shared/ButtonDelete/ButtonDelete";
import SessionManager from './../../common/SessionManager';
import axios from 'axios';
import constants from 'common/constants';
import ClientStore from "../../common/ClientStore";
import ModalEditBooking from "./ModalEditBooking";

const moment = require('moment');


export interface CartDetailsTableProps {
  className?: string;
  data:Order;
  mrpPriceMap: any;
}


function onClickDelete(orderId:any) {
  if (window.confirm("Are you sure, you want to remove item from cart?")) {

    if (SessionManager.getUser() != null) {
      SessionManager.getJWTToken((jwtToken: any) => {

        axios.delete(constants.API_CONTEXT_URL + `/order/deleteOrder?orderId=${orderId}`, {
          headers: {
            Authorization: 'Bearer ' + jwtToken
          }
        }).then((response) => {
          //window.location.assign("CheckSummary#/CheckSummary");
          let currentCartCount = ClientStore.get('localstorage', 'orders_count') ?? 0;
          ClientStore.set('localstorage', 'orders_count', currentCartCount - 1);
          window.location.reload();
        });
      });
    }
    else {
      window.location.assign("/login");
    }
  }

}

const CartDetailsTable: FC<CartDetailsTableProps> = ({ className = "",data,mrpPriceMap }) => {
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [selectedPackage, setselectedPackage] = useState<GeneralPackage>();
  const [selectedPackageId, setSelectedPackageId] = useState<string>();
  const [selectedAnchor, setSelectedAnchor] = useState<any>();
  const [product, setProduct] = useState<ProductDataType>();
  const [dateValue, setdateValue] = useState<moment.Moment | null>(moment());
  const [orderDetails, setorderDetails] = useState<Order>();
  
  function getTotalCount(data:Order) {
    let count = data.guestListRequired == true ? data.ticketDetails?.length ?? 0 : data.nonGuestCount;
    if(data?.type?.includes("Yacht")||data?.type == "SpeedBoat"){
      return data.nonGuestCount;
    }
    return count;
  }
  function closeBookingModal() {
    setIsOpenBookingModal(false);
  }

  function openBookingModal() {
    setIsOpenBookingModal(true);
  }

  const renderBookingModal = () => {
    return (
      <ModalEditBooking closeBookingModal={closeBookingModal}
       isOpenModal={isOpenBookingModal} product={product} selectedPackageId={selectedPackageId}
      selectedPackage={selectedPackage}
      dateValue={dateValue} selectedAnchor={selectedAnchor}
      setdateValue={setdateValue} orderDetails={orderDetails}/>
    );
  }
  function openModalNow(){
    openBookingModal();
  }
  function HandleEditClick(order:Order) {
    SessionManager.getJWTToken((jwtToken: string) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwtToken,
        },
      };
      fetch(constants.API_CONTEXT_URL + '/product/seller/viewProduct?productId=' + order?.productId, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setProduct(data.response);
        setdateValue(moment(order.eventDate));
        setorderDetails(order);
        //setselectedRate(order?.price);
        let packageItem: GeneralPackage = data.response?.packages.filter((item: GeneralPackage) => item.id == order?.packageId)[0];
        setselectedPackage(packageItem);
        setSelectedPackageId(packageItem.id);
        setSelectedAnchor(packageItem.packageItems?.filter((item: packageItems) => item.title == "Anchoring details")[0])
        openModalNow();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    });
    
  }
  return (
    <div className="lg:p-4 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
      <div
        className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
         dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      >
        <div className={` sm:pr-20 relative  ${className}`}>
          <div className="flex sm:flex-row sm:flex-wrap sm:items-center space-y-6 sm:space-y-0">

            {/* FOR MOBILE RESPONSIVE */}
            <div className="block lg:hidden space-y-1">
              <div className="flex font-semibold">
                <div>
                  <span>{data.projectName}</span>
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                    {(data?.type?.includes("Yacht")||data?.type == "SpeedBoat") ? 'Riding Hours' :
                    data?.type?.includes("Wheel") || data?.type?.includes("Vehicle")
                || data?.type?.includes("Cycle") || data?.type?.includes("Car") ? 'Number of Day(s)' : 'Number of Guests'}<span className="mx-2">·</span>
                    {getTotalCount(data) }
                  </span>
                </div>
                <span className="w-12 flex justify-center">
                  <i className=" text-2xl las la-long-arrow-alt-right"></i>
                </span>
                <div>
                  <span>{data.packageName}</span>
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  ₹{mrpPriceMap[data?.orderId]}
                  </span>
                </div>
              </div>

              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                <span className="VG3hNb">Event Date</span>
                <span className="mx-2">·</span>
                <span>{moment(`${data.eventDate}`).format('DD MMM YYYY')}</span>
              </div>
            </div>

            {/* NAME */}
            <div className="hidden lg:block min-w-[220px] flex-[4] ">
              <div className="font-medium text-lg">{data.projectName}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.packageName}
              </div>
            </div>

            {/* TIME */}
            <div className="hidden lg:block min-w-[150px] flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg"> {moment(`${data.eventDate}`).format('DD MMM YYYY')}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {(data?.type?.includes("Yacht")||data?.type == "SpeedBoat") ? 'Riding Hours' :
                    data?.type?.includes("Wheel") || data?.type?.includes("Vehicle")
                || data?.type?.includes("Cycle") || data?.type?.includes("Car") ? 'Number of Day(s)' : 'Number of Guests'}: {getTotalCount(data) }
              </div>
            </div>

            {/* TYPE */}
            <div className="hidden lg:block min-w-[100px] flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">₹{mrpPriceMap[data?.orderId]}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.type}
              </div>
            </div>

            <div className="flex-[4] flex pt-4 items-left whitespace-nowrap sm:text-right">            
            <ButtonSecondary onClick={()=>HandleEditClick(data)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-red-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3 text-red-400">Edit</span>
              
              </ButtonSecondary>

              <div className="flex items-center justify-between py-3">
                <ButtonDelete className="lex items-center ml-6 text-red-400" onClick={()=>onClickDelete(data.orderId)} />
              </div>
            </div>
            {orderDetails != undefined ? renderBookingModal()
            : null}
          </div>
          {data?.invalidOrdersPresent ? 
          <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          <span className="mt-3 text-red-400">{data?.errorMessage}</span>
          </div>
          : null }
        </div>
      </div>
    </div>
  );
};
export default CartDetailsTable;
